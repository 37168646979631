.events-container {
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.wholeevent{
  background-image:linear-gradient(270deg, #28b8d5 0%,#020344 100%);
}
.events_tab{
  background-image:linear-gradient(270deg, #28b8d5 0%,#020344 100%);
}
.wholeevent .section-title .past{
  font-size: 4vw;
}
.wrapper {
  display: flex;
  width: 90%;
  justify-content: space-around;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: column;
    align-items: center;
    margin: 50px 0;
  }
  .card {
    margin-bottom: 50px !important;
  }
  .wholeevent .section-title .past{
    font-size: 6vw;
  }
}

.card {
  width: 300px;
  height: 300px;
  border-radius: 15px;
  padding: 1.5rem;
  background: white;
  position: relative;
  display: flex;
  align-items: center;
  transition: 0.4s ease-out;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
  margin: 10px;
}
.card:hover {
  transform: translateY(1%);
  cursor: pointer;
}
.card:hover:before {
  opacity: 1;
}
.card:hover .info {
  opacity: 1;
  transform: translateY(0px);
}

.card .info h1 {
  text-align: center;
  /* text-transform: uppercase; */
  font-weight: bold;
  margin: auto;
  margin-bottom: -50px !important;
}

.card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}
.card img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
}
.card .info {
  position: relative;
  z-index: 3;
  color: white;
  opacity: 0;
  transform: translateY(30px);
  transition: 0.5s;
}
.card .info h1 {
  margin: auto;
  padding-bottom: 80px;
  padding-left: 50%;
}
.event_info_name{
  font-family: Gotu;
}
.events_tab {
  color: black;
  text-align: center;
  font-family: "Gotu", sans-serif;
  font-size: 28px;
  /* padding-top: 15%; */
  font-weight: bold;
  padding-bottom:1%;
  background-color:  #a6c1ee;
}

.events_tab:hover {
  color: red;
  cursor: pointer;
}

.info-t {
  margin: auto;
  padding-left: 10px !important;
  text-align: center;
  font-family: Gotu;
 
}

.past {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    rgb(201, 62, 62) 0%,
    #bac42e 29%,
    #ff1361 67%,
    #c61fb3 100%
  );
  background-clip: border-box;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 50px;
  font-weight: bold;
  text-align: center;

  background-color: #fff;
  font-family: Gotu;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.event1{
  background-image: linear-gradient(#28b8d5,#020344) !important;
}
