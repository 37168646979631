.gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;
    cursor: pointer;
    background-color: black;
    margin-left: 3%;
    margin-right: 3%;
}

.gallery .pics {
    margin-bottom: 12px;
    filter: grayscale(100%);
    transition: filter 0.6s cubic-bezier(0.680, -0.550, 0.265, 1.550);
}

.gallery .pics:hover {
    filter: grayscale(0%);
}

@media(max-width: 991px) {
    -gallery {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}

@media(max-width:480px) {
    .gallery {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 60%;
        -moz-column-width: 60%;
        column-width: 60%;
        padding: 0 12px;
    }
}

.model {
    width: 50%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.model.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    display: flex;
    margin-left: 25%;
}

.model img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.model.open svg {
    position: fixed;
    /* margin-bottom: 53%; */
    top: 15%;
    right: 10px;
    width: 3rem;
    height: 3rem;
    /* padding: 5px; */
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    cursor: pointer;
}


/* Scroll Down */

.scroll-container {
    bottom: 28%;
    left: 49%;
    position: absolute;
    width: 40px;
    height: 40px;
    /* border: 2px solid white; */
}

.chevron {
    position: absolute;
    width: 28px;
    height: 8px;
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
    animation: move 3s ease-out infinite;
}

.chevron:first-child {
    animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
    animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
    content: ' ';
    position: absolute;
    top: 0;
    height: 100%;
    width: 51%;
    background: #fff;
}

.chevron:before {
    left: 0;
    transform: skew(0deg, 30deg);
}

.chevron:after {
    right: 0;
    width: 50%;
    transform: skew(0deg, -30deg);
}

@keyframes move {
    25% {
        opacity: 1;
    }
    33% {
        opacity: 1;
        transform: translateY(30px);
    }
    67% {
        opacity: 1;
        transform: translateY(40px);
    }
    100% {
        opacity: 0;
        transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
    }
}

.text1 {
    display: block;
    margin-top: 75px;
    margin-left: -30px;
    font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
    font-size: 12px;
    color: #000;
    text-transform: uppercase;
    white-space: nowrap;
    opacity: .25;
    animation: pulse 2s linear alternate infinite;
}

@keyframes pulse {
    to {
        opacity: 1;
    }
}

.header_content {
    height: 500px;
}