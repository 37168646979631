@import url("https://fonts.googleapis.com/css2?family=Amita&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gotu&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Ubuntu:wght@400;500;700&display=swap");

:root{
    --font-base:'raleway',sans-serif;
    --transition:all 300ms ease-in-out;
    --red-color:#d37400;
    --dark-color:#464646;
    --light-grey:#f4f4f4;
    --white-color:#fff;

}
*{
box-sizing: border-box;
padding: 0;
margin:0;
scroll-behavior: smooth;
}

html{
    font-size:10px;

}
body{
    font-size:1.8rem;
    line-height: 1.6;
    font-family: var(--font-base);
    color:var(--dark-color);
}
a{
   color:unset;
   text-decoration: none; 
}
ul{
    list-style-type: none;
}
img{
    width: 100%;
    display: block;
}
button{
    cursor: pointer;
    outline:0;
    border:none;
    background-color: transparent;
    font-family: inherit;
    font-size: 1.8rem;
}
input,textarea{
outline:0;
border:none;
resize:none
}
h1,h2,h3,h4{
    line-height: 1.5;
    letter-spacing: 1px;
    font-family: "Ubuntu", sans-serif;
}
