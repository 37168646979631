.contact {
  position: relative;
  min-height: 100vh;
  padding: 50px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(270deg, #28b8d5 0%,#020344 100%);  background-size: cover;
}

.contact .content {
  max-width: 800px;
  text-align: center;
}

.contact .content h2 {
  font-size: 36px;
  font-weight: 500;
  color: #fff;
}

.contact .content p {
  font-weight: 300;
  color: #fff;
}

.contact-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.contact-container .contactInfo {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.contact-container .contactInfo .box {
  position: relative;
  padding: 20px 0;
  display: flex;
}

.contact-container .contactInfo .box .icon {
  min-width: 60px;
  height: 60px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 22px;
}

.contact-container .contactInfo .box .text {
  display: flex;
  margin-left: 20px;
  font-size: 16px;
  color: #fff;
  flex-direction: column;
  font-weight: 300;
}

.contact-container .contactInfo .box .text h3 {
  font-weight: 500;
  color: #00bcd4;
  border-radius: 20px;
}

.contactForm {
  width: 40%;
  padding: 40px;
  background: #fff;
  border-radius: 20px;
}

.contactForm h2 {
  font-size: 30px;
  color: #333;
  font-weight: 500;
  text-align:center
}

.contactForm .inputBox {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.contactForm .inputBox input,
.contactForm .inputBox textarea {
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}

.contactForm .inputBox span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #666;
}

.contactForm .inputBox input:focus ~ span,
.contactForm .inputBox input:valid ~ span,
.contactForm .inputBox textarea:focus ~ span,
.contactForm .inputBox textarea:valid ~ span {
  color: #e91e63;
  font-size: 12px;
  transform: translateY(-20px);
}

.contactForm .inputBox input[type="submit"] {
    width: 100px;
    background: rgb(6,14,131);
    background: linear-gradient(0deg, rgba(6,14,131,1) 0%, rgba(12,25,180,1) 100%);
    border: none;
    color: #fff;
    border:none;
    cursor: pointer;
    padding:10px;
    font-size:18px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    margin: auto;
    text-align: center;
}

.contactForm .inputBox input[type="submit"]:hover {
background: rgb(0,3,255);
background: linear-gradient(0deg, rgba(0,3,255,1) 0%, rgba(2,126,251,1) 100%);
}

@media (max-width:991px){
    .contact{
        padding:50px;
    }
    .contact-container{
        flex-direction: column;
    }
    .contact-container .contactInfo{
        margin-bottom:40px;
    }
    .contact-container .contactInfo,.contactForm{
        width:100%;
    }
}

@media (max-width:400px){
  p{
    font-size:12px;
  }
}