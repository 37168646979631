.sponsors-content {
  /* gap: 1rem; */
  grid-template-columns: repeat(1, 1fr);
  border: 3px;
}

#sponsors {
  /* background-image: linear-gradient(#28b8d5, #020344); */
  text-align: center;
}
.container .section-title .big{
  font-size: 4vw;
  font-weight: bolder;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sponsors-item {
  padding-left: 2rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  min-height: 160px;
  justify-content:center;
  cursor: pointer;
  align-content: center;
  transition: var(--transition);
 
}

.sponsors-item img {
  max-width: 60%;
  border-radius: 15%;
}
.sponsors-item h4 {
  opacity: 0.7;
}
@media screen and (min-width: 600px) {
  .sponsors-content {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 480px) {
  .sponsors-content {
    grid-template-columns: repeat(2, 1fr);
  }
  .sponsors-item img {
    max-width: 100%;
    border-radius: 15%;
  }
  .sponsors .container .section-title{
    text-align: center;
  }
  .container .section-title .big{
    font-size: 13vw;
    font-weight: bolder;
  }
  #sponsors{
    margin-top: 20vw;
  }
}

@media screen and (min-width: 900px) {
  .sponsors-content {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media screen and (max-width: 800px) {
  #sponsors {
    padding-top: 40%;
  }
}
