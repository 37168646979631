* {
  outline: none;
  border-bottom: 0;
  font-family: Gotu;
  text-align: center;
}
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
}
body {
  font-family: Gotu;
  background-color: black;
}
.section-padding {
  padding: 6rem 0;
}
.flex {
  display: flex;
}
.flex-center {
  align-items: center;
  justify-content: center;
}
.grid {
  display: grid;
}

/* texts and paras */
.text {
  line-height: 1.7;
  font-weight: 300;
  opacity: 0.9;
  letter-spacing: 1px;
  font-family: "Ubuntu", sans-serif;
}
.text-center {
  text-align: center;
}
.text-upper {
  text-transform: uppercase;
}
.text-cap {
  text-transform: capitalize;
}
.text-white {
  color: var(--white-color);
}
.text-dark {
  color: var(--dark-color);
}
.text-red {
  color: var(--red-color);
}
.text {
  margin: 1rem 0;
}

/* fonts */
.fw-2 {
  font-weight: 200;
}
.fw-3 {
  font-weight: 300;
}
.fw-4 {
  font-weight: 400;
}
.fw-5 {
  font-weight: 500;
}
.fw-6 {
  font-weight: 600;
}
.fw-7 {
  font-weight: 700;
}
.fw-8 {
  font-weight: 800;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}

/* background */
.bg-tranparent {
  background-color: transparent;
}
.bg-white {
  background-color: var(--white-color);
}
.bg-dark {
  background-color: var(--dark-color);
}
.bg-red {
  background-color: var(--red-color);
}
.bg-lightgrey {
  background-color: var(--light-grey);
}

/* button */
.btn {
  display: inline-block;
  color: var(--white-color);
  letter-spacing: 1px;
  transition: var(--transition);
  text-transform: capitalize;
  padding: 1rem 1.8rem;
  margin-top: 1.8rem;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.1) 0 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0 4px 6px -2px;
}

.btn:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0 10px 15px -3px,
    rgba(0, 0, 0, 0.1) 0 4px 6px -2px;
  scale: 1.1;
}
.btn div {
  margin-right: 0.4rem;
  display: inline-block;
}
.btn-dark {
  background-color: var(--dark-color);
}
.btn-red {
  background-color: var(--red-color);
}

/* spacing */

.ls-1 {
  letter-spacing: 1px;
}
.ls-2 {
  letter-spacing: 2px;
}
.py-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.py-4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
/* margin auto */
.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

/* animation and transition stopper*/
.resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}
.section-title {
  width: 60%;
  margin: 0px auto !important;
  text-align: center;
  /* border-radius: 50%; */
}
.section-title::after {
  content: "";
  position: absolute;
  margin: auto;
  width: 100%;
  transform: scaleX(0);
  border: 1px solid #fff;
  border-bottom-left-radius: 50px 20px;
  border-bottom-right-radius: 50px 20px;
  height: 5px;
  bottom: 0;
  left: 0;
  background-color: #800000;
  transition: all 0.5s ease !important;
  background-image: url("https://sunburn.in/wp-content/themes/salient-child/img/nav-brush-stroke.png") !important;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}


.App {
  overflow-x: hidden;
}

.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #fff 0%,
    #52de45 29%,
    #a95e77 67%,
    #fff800 100%
  );
  font-family: Gotu;
  background-size: auto auto;
  background-clip: border-box;
  background-size: 100% auto;
  color: #fff;
  background-clip: text;
  -text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  /* display: inline-block; */
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.lineUp {
  animation: 4s anim-lineUp ease-out;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.heading {
  border-radius: 50px;
}

img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ff6600;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffae42;
}
#loading {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgb(0, 0, 0) url("https://rangawardhan.in/img/Rangawardhan.gif")
    no-repeat center center;
  z-index: 99999;
  background-size: 200px 200px;
}
