.Navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    background-color: transparent;
    transition: background-color 0.3s ease;
    z-index: 1000;
}

.navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 20px;
}

body {
    margin-top: 80px;
}

.scrolled .Navbar {
    background-color: rgba(255, 255, 255, 0.5);
}

.brand-and-toggler {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
}

.navbar-links {
    display: flex;
    gap: 150px;
}

.nav-link {
    font-weight: normal;
    color: #FFFFFF;
    position: relative;
}

.nav-link:hover {
    color: #eae316;
}

.nav-link::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #eae316;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.nav-link:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.navbar-brand {
    display: flex;
    align-items: center;
}

.navbar-brand h3 {
    color: #FFFFFF;
}

.navbar-brand img {
    filter: none;
}

.navbar-brand:hover h3 {
    color: #FFFFFF;
}

.navbar-show-btn {
    background-color: white;
    color: black;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.navbar-show-btn:hover {
    background-color: #f0f0f0;
}

.navbar-collapse {
    position: fixed;
    right: 0;
    top: 0;
    background-color: var(--dark-color);
    height: 100%;
    width: 100%;
    padding: 4rem;
    transform: translateX(100%);
    transition: var(--transition);
    z-index: 5;
}

.show-navbar {
    transform: translate(0);
}

.navbar-collapse .navbar-hide-btn {
    position: absolute;
    right: 2rem;
    top: 2rem;
    transition: var(--transition);
}

.navbar-collapse .nav-item {
    margin: 2rem 0;
    font-family: "Ubuntu", sans-serif;
}

.navbar-collapse .nav-link {
    transition: var(--transition);
    display: inline-block;
}

.navbar-collapse .nav-link:hover {
    transform: scale(1.2);
}

.navbar-collapse .navbar-hide-btn:hover {
    opacity: 0.9;
}

@media screen and (max-width: 480px) {
    .navhead {
        font-size: 60%;
    }
    .navbar-links {
        display: none;
    }
    .navbar-collapse {
        width: 100%;
    }
    .navbar-collapse .nav-item {
        font-size: 18px;
    }
    .ranga-logo {
        max-width: 50px;
        height: 50px;
    }
}

@media screen and (min-width: 480px) {
    .navbar-collapse {
        width: 300px;
    }
}

.ranga-logo {
    max-width: 60px;
    height: 60px;
}

#google_translate_element {
    width: 30px !important;
    height: 30px !important;
}

div#google_translate_element div.goog-te-gadget-simple {
    font-size: 0px;
    background-color: black;
    height: 22px;
    width: 22px;
    cursor: pointer;
}

div#google_translate_element div.goog-te-gadget-simple a.goog-te-menu-value span {
    color: white;
    display: none !important;
}

div#google_translate_element div.goog-te-gadget-simple a.goog-te-menu-value span:hover {
    color: yellow;
}