* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body::after {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.container .section-title .animate-charcter{
  font-size: 4vw;
}
.team {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 6vh 0;
  margin-top: 20px;
  flex-direction: column;
}

.team h1 {
  font-size: 45px;
  font-weight: 600;
  color: #000;
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 8px;
}
.container {
  color: #fff;
}
.team h1::after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 3px;
  width: 150px;
  background-color: #eee;
  border-radius: 50%;
}

.team-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.team-box {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid #000;
  margin: 0 20px;
  margin-bottom: 15px;
  cursor: pointer;
  position: relative;
}

.team-box img {
  vertical-align: bottom;
  transition: all 0.3s ease;
}
.team-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: scale(0);
  transition: all 0.3s ease;
  transform-origin: 0%;
}
.team-name {
  font-size: 25px;
  font-weight: 600;
  color: #000;
  transform: scale(0);
  transform-origin: 0%;
  transition: all 0.3s ease;
}
.team-details {
  font-size: 16px;
  color: #000;
  font-weight: 500;
  margin-top: -10px;
  transition: all 0.3s ease;
  transform: scale(0);
  transform-origin: 0%;
}

.team-social-links {
  display: flex;
  justify-content: center;
  transition: all 0.3s ease;
  transform: scale(0);
  transform-origin: 0%;
}

.team-social-links a {
  width: 35px;
  height: 35px;
  background-color: #fff;
  margin: 15px;
  color: #0059ff;
  display: flex;
  justify-content: center;
  text-decoration: none;
  font-size: 18px;
  border-radius: 50%;
  padding-top: 8px;
}

.team-social-links a:hover {
  background-color: transparent;
  color: #eee;
}

.team-box:hover .team-info {
  transform: scale(1);
}

.team-box:hover img {
  transform: scale(1.2);
}

.team-box:hover .team-name,
.team-box:hover .team-details,
.team-box:hover .team-social-links {
  transform: scale(1);
}

.team-box:hover .team-name {
  transition: all 0.3s ease 0.2s;
}

.team-box:hover .team-details {
  transition: all 0.3s ease 0.3s;
}

.team-box:hover .team-social-links {
  transition: all 0.3s ease 0.4s;
}

.custom-btn {
  margin:0 40%;
  width: 330px;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display:flex;
  align-items: center;
  justify-content: center;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
}

.btn-5 {
  background: rgb(255,151,0);
  border: none;
  z-index: 1;
  width:29%;
}
.btn-5:after {
  position: absolute;
  content: "";
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 5px;
  background-color: #eaf818;
  background-image: linear-gradient(315deg, #eaf818 0%, #f6fc9c 74%);
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5)
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  transition: all 0.3s ease;
}
.btn-5:hover {
  color: #000;
}
.btn-5:hover:after {
  top: auto;
  bottom: 0;
  height: 100%;
}
.btn-5:active {
  top: 2px;
}
.name,
.post {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

@media screen and (max-width:480px){
  .custom-btn{
    margin-left: 30%;
    font-size: 15px;
    width:50vw;
    font-weight: bold;
    padding: 5%;
  }
  .past{
    margin-top: 15px;
    font-size: 25px;
  }
}


@media screen and (min-width:800px){
  .custom-btn{
    width:200px;
    font-weight: bold;
  }
  .past{
    margin-top: 15px;
    font-size: 25px;
  }
}